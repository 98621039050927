.btn-whatsapp-content {
  margin: 0 40px;
  z-index: 2;
}

.whatsapp-text {
  margin-left: 10px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.05em;
  color: #6F6D7A;
}

.whatsapp-icon {
  margin-left: 20px;
  width: 16px;
  height: 16px;
}

@media all and (max-width: 999px) {
  .btn-whatsapp-content {
    align-self: center;
    margin: 0;
    align-items: center;
  }

  .whatsapp-text {
    font-size: 10px;
  }
}
