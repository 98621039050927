.what-we-offer-service-item {
  width: 300px;
  z-index: 1;
  margin-bottom: 30px;
}

.what-we-offer-service-item-text {
  margin-top: 25px;
  margin-right: 30px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: #36373E;
  text-align: left;
}

@media all and (max-width: 999px) {
  .what-we-offer-service-item {
    width: 250px;
  }
}
