.label-input-container {
  margin-bottom: 15px;
}

.input-label {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #6F6D7A;
  text-align: left;
}

.label-input-container-margin input {
  width: 80%;
}

.input-text {
  background: #FFFFFF;
  border: 1px solid #DDDCDF;
  height: 45px;
  padding: 0 10px;
}
