.circle-icon-text-container {
  margin-top: 20px;
  background-color: transparent;
  border: none;
  text-align: left;
}

.circle-icon-text-content {
  padding-left: 20px;
}

.circle-icon-title {
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.03em;
  color: #DDDCDF;
  margin: 0;
}

.circle-icon-text {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 14px;
  line-height: 180%;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin: 0;
}
