#page-our-services-banner {
  height: 210px;
  position: relative;
}

.page-our-services-banner-title {
  font-size: 38px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #1D1E20;
  margin: 0;
  z-index: 1;
}

#page-our-services-banner .banner-left-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  z-index: 0;
}

.banner-right-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 300px;
  z-index: 0;
}

@media all and (max-width: 999px) {
  #page-our-services-banner .banner-left-bg {
    display: none;
  }
}
