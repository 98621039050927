#section-initial {
  padding: 0 8% 30px;
  position: relative;
}

.section-initial-content {
  margin: 0 40px;
  z-index: 23;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.section-initial-content-left {
  z-index: 1;
}

@media all and (min-width: 1000px) {
  .section-initial-content-left {
    width: 450px;
  }
}

.section-initial-title {
  font-size: 28px;
  line-height: 135%;
  letter-spacing: 0.05em;
  width: 360px;
  text-align: left;
  z-index: 1;
}

.section-initial-text {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  font-family: Montserrat;
  width: 360px;
  text-align: left;
  color: #1D1E20;
  z-index: 1;
}

.section-initial-right-content, .img-aperto-mao, .section-initial-right-content-bg {
  width: 350px;
  height: 450px;
  position: relative;
  z-index: 1;
}

.section-initial-right-content-bg {
  position: absolute;
  left: 20px;
  top: 20px;
  background-color: #DDDCDF;
  z-index: 0;
  opacity: 0.3;
}

.section-initial-right-text-container {
  position: absolute;
  width: 310px;
  height: 90px;
  right: 50px;
  bottom: 30px;
  background-color: #300020;
  z-index: 2;
}

.section-initial-right-text {
  position: absolute;
  top: 17px;
  right: 30px;
  color: #FFFFFF;
  width: 207px;
  height: 52px;
  font-size: 16px;
  font-weight: bold;
  line-height: 170%;
  letter-spacing: 0.05em;
  text-align: left;
}

.quote-icon-container {
  position: relative;
  width: 70px;
  height: 70px;
  left: -30px;
  top: 10px;
  background-color: #1A0816;
}

.img-quote {
  width: 33px;
  height: 25px;
}

.img-initial-divisor-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 230px;
  opacity: 0.8;
}

@media all and (max-width: 999px) {
  .section-initial-title {
    font-size: 23px;
    width: 280px;
  }

  .section-initial-text {
    font-size: 14px;
    width: 280px;
  }

  .section-initial-content {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }

  .section-initial-content-left {
    align-self: center;
    margin-left: 15px;
  }

  .section-initial-right-content {
    margin: 20px 0;
  }

  .section-initial-right-content, .img-aperto-mao, .section-initial-right-content-bg {
    width: 250px;
    height: 321px;
  }

  .section-initial-right-content-bg {
    left: 5px;
    top: 5px;
  }

  .section-initial-right-text-container {
    width: 220px;
    height: 45px;
    right: 50px;
    bottom: 20px;
  }

  .quote-icon-container {
    width: 35px;
    height: 35px;
    top: 5px;
    left: -10px;
  }

  .img-quote {
    width: 18px;
    height: 14px;
  }

  .section-initial-right-text {
    font-size: 10px;
    top: 6px;
    right: 23px;
    width: 150px;
  }
}
