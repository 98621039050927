.page-our-services-other-service-divider {
  width: 1px;
  height: 60px;
  background: #DDDCDF;
  margin: 0 50px;
}

.page-our-services-other-service-text {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: #1D1E20;
}

.page-our-services-other-service {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media all and (max-width: 815px) {
  .page-our-services-other-service {
    flex-direction: column;
  }

  .page-our-services-other-service-divider {
    display: none;
  }

  .page-our-services-other-service-text {
    margin-top: 30px;
  }
}
