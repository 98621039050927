#section-our-services {
  padding: 80px 0 30px 8%;
  position: relative;
}

.img-our-services-right-bg {
  position: absolute;
  top: 0;
  right: 0;
  height: 450px;
  z-index: 0;
}

.section-our-services-content {
  padding: 0 50px;
  z-index: 10;
}

.section-our-services-title {
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  text-align: left;
  margin-bottom: 60px;
  z-index: 2;
}

@media all and (min-width: 1000px) {
  .section-our-services-title {
    width: 450px;
  }
}

@media all and (max-width: 999px) {
  .section-our-services-content {
    padding: 0;
  }

  .section-our-services-title, .section-our-services-items-container {
    margin-left: 30px;
  }

  .section-our-services-title {
    font-size: 21px;
  }
}
