.service-item-container {
  padding: 30px 10px 30px 30px;
}

.service-item-container-no-border {
  border: none;
}

.service-item-container-border {
  border-right: 1px solid #ECEAEF;
}

.service-item-text {
  font-size: 18px;
  letter-spacing: 0.08em;
  line-height: 130%;
  margin-left: 15px;
}

a.service-item-link {
  color: #6F6D7A;
  font-family: Montserrat;
}

.service-item-img-container {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background-color: #1D1E20;
}

.service-item-text-container {
  display: flex;
  flex-direction: column;
}

@media all and (max-width: 1200px){
  .service-item-container {
    padding: 20px 5px 20px 10px;
  }

  .service-item-text {
    font-size: 15px;
  }

  a.service-item-link {
    font-size: 12px;
  }
}

@media all and (max-width: 999px){
  .service-item-container-border {
    border: none;
  }

  .service-item-text-container {
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
  }

  .service-item-text {
    font-size: 17px;
    margin-left: 5px;
  }

  a.service-item-link {
    font-size: 13px;
    align-self: center;
  }
}
