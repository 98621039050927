.section-left, .section-left-text-container {
  width: 40px;
  z-index: 5;
}

.section-left-text-container {
  position: relative;
}

.section-left-text {
  position: absolute;
  transform: rotate(-90deg);
  bottom: 0;
  top: 0;
  font-size: 32px;
  line-height: 110%;
  letter-spacing: 0.05em;
}

.section-left-border {
  flex: 1;
  position: relative;
  width: 1px;
  bottom: -30px;
}
