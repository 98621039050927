.page-our-services-item-img-container {
  width: 60px;
  height: 60px;
  background-color: #1D1E20;
}

.page-our-services-item-img-container img {
  width: 30px;
  height: 30px;
}

.page-our-services-item-text {
  width: 170px;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  margin-left: 23px;
}

.page-our-services-item-text p {
  margin: 0;
}
