#page-our-services-assist-jurid {
  position: relative;
  background-color: #FFFFFF;
  padding: 80px 8% 30px;
}

.page-our-services-assist-jurid-info {
  margin-top: 30px;
  background: #F9F9F9;
  border: 1px dashed #DDDCDF;
  padding: 25px 30px;
}

.page-our-services-assist-jurid-info-text {
  font-family: Montserrat;
  font-size: 12px;
  line-height: 180%;
  letter-spacing: 0.03em;
  color: #1D1E20;
  margin: 0;
}

.page-our-services-assist-jurid-info-text strong {
  text-decoration: underline;
}
