#section-initial-divider {
  border-top: 1px solid #ECEAEF;
  padding: 0 8%;
}

.initial-divider-content-square {
  width: 110px;
  height: 34px;
  background-color: rgba(169, 167, 175, 0.2);
  font-size: 13px;
  font-weight: 700;
  color: #6F6D7A;
}

.section-initial-divider-items {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media all and (max-width: 999px) {
  #section-initial-divider {
    padding: 0 4%;
  }

  .initial-divider-content-square {
    display: none;
  }

  .section-initial-divider-items {
    flex-direction: column;
    align-items: stretch;
  }
}
