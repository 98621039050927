#section-who-we-are {
  padding: 80px 8% 30px;
  position: relative;
}

.section-who-we-are-content {
  padding: 0 0 0 60px;
}

.section-who-we-are-content-title {
  display: flex;
  flex-direction: row;
}

.section-who-we-are-first-text {
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.04em;
  color: #1D1E20;
  padding: 0 30px 0 0;
  text-align: left;
}

.section-who-we-are-second-text {
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  padding: 0 0 0 30px;
  text-align: left;
}

.section-who-we-are-second-text .section-who-we-are-second-text-first {
  margin-bottom: 23px;
}

.section-who-we-are-title {
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1D1E20;
  margin-bottom: 30px;
}

.section-who-we-are-title-border {
  flex: 1;
  position: relative;
  height: 1px;
  background-color: #DDDCDF;
  margin-left: 30px;
}

.section-who-we-are-content-2 {
  padding-top: 80px;
}

.section-who-we-are-text {
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  padding: 30px 45px 0 0;
}

.section-who-we-are-text-2-container {
  display: flex;
  flex-direction: row;
}

.section-who-we-are-text-2 {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  padding: 0 45px 30px 0;
}

.section-who-we-are-vision-values {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-who-we-are-side {
  flex: 1;
  text-align: left;
  margin-top: 50px;
}

@media all and (min-width: 1000px){
  .section-who-we-are-side {
    width: 230px;
  }
}

.section-who-we-are-side > .section-who-we-are-title {
  text-align: left;
}

.dot_img {
  width: 6px;
  height: 6px;
}

.section-who-we-are-side p.section-who-we-are-text-2 {
  margin: 0 0 0 8px;
}

.who-we-are-logo {
  width: 60px;
  height: 60px;
}

.who-we-are-arrow {
  width: 4px;
  height: 8px;
}

.who-we-are-arrow-top {
  width: 8px;
  height: 4px;
}

.item-value {
  margin-bottom: 15px !important;
}

.who-we-are-circle {
  position: relative;
  margin: 80px 6% 80px 40px;
  border: 1px solid #DDDCDF;
  width: 420px;
  height: 420px;
  border-radius: 50%;
  padding: 15px;
}

.who-we-are-circle-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #51152E;
}

.who-we-are-circle-arrow-top {
  top: -10px;
  right: 50%;
  left: 50%;
}

.who-we-are-circle-arrow-right {
  right: -10px;
  top: 50%;
  bottom: 50%;
}

.who-we-are-circle-arrow-left {
  left: -10px;
  top: 50%;
  bottom: 50%;
}

.who-we-are-circle-content {
  border: 1px solid #DDDCDF;
  border-radius: 50%;
}

.who-we-are-circle-content-1 {
  background-color: rgba(221, 220, 223, 0.5);
  padding: 40px;
}

.who-we-are-circle-content-2 {
  background-color: rgba(221, 220, 223, 0.2);
  padding: 45px;
}

.who-we-are-circle-content-3 {
  background-color: #FFFFFF;
  padding: 45px;
}

.who-we-are-circle-content-4 {
  background-color: #FFFFFF;
  padding: 45px;
  box-shadow: 0 4px 10px rgba(111, 109, 122, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 1200px){
  .who-we-are-circle {
    width: 250px;
    height: 250px;
  }

  .who-we-are-circle-content-1 {
    padding: 23px;
  }

  .who-we-are-circle-content-2 {
    padding: 25px;
  }

  .who-we-are-circle-content-3 {
    padding: 25px;
  }

  .who-we-are-circle-content-4 {
    padding: 25px;
  }

  .who-we-are-logo {
    width: 40px;
    height: 40px;
  }
}

@media all and (max-width: 999px){
  #section-who-we-are {
    padding: 40px 4% 30px;
  }

  .section-who-we-are-content {
    padding: 0 0 0 20px;
  }

  .section-who-we-are-content-title {
    flex-direction: column;
  }

  .section-who-we-are-first-text {
    font-size: 21px;
    padding: 0;
    margin-bottom: 20px;
  }

  .section-who-we-are-second-text {
    padding: 0;
  }

  .section-who-we-are-content-2 {
    padding-top: 30px;
  }

  .section-who-we-are-text-2-container {
    flex-direction: column;
  }

  .section-who-we-are-vision-values {
    flex-direction: column;
  }

  .section-who-we-are-side {
    margin: 0;
  }

  .who-we-are-circle {
    display: none;
  }
}
