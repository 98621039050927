footer {
  height: 100px;
  position: relative;
  background-color: #FFFFFF;
}

footer p {
  font-size: 15px;
  line-height: 130%;
  text-align: center;
  letter-spacing: 0.05em;
  color: #6F6D7A;
  padding: 15px !important;
}
