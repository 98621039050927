header {
  height: 75px;
  background-color: #FFFFFF;
  z-index: 10;
  position: relative;
}

header .header-center {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin: 0 9%;
}

.header-logo {
  width: 260px;
}

.header-menu-item {
  background-color: transparent;
  position: relative;
  font-size: 13px;
}

.header-menu-item:hover {
  cursor: pointer;
  color: #BA4951;
}

a.header-menu-item {
  text-decoration: none;
  color: #000000;
}

a.header-menu-item:visited {
  text-decoration: none;
}

.header-menu-item::after {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  content: '';
  width: 100%;
  border-bottom: 1px solid #BA4951;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
}

.header-menu-item:hover::after {
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.header-divider {
  width: 4px;
  height: 4px;
  margin: 0 20px;
  background-color: #51152E;
}

@media all and (max-width: 815px) {
  header {
    height: 130px;
  }
  header .header-center {
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
  }
}
