#page-our-services-items {
  position: relative;
  background-color: #FFFFFF;
  padding: 50px 8% 30px;
}

@media all and (max-width: 815px) {
  #page-our-services-items {
    padding: 50px 4% 30px;
  }
}
