.our-service-item {
  width: 225px;
  height: 90px;
  margin-right: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(221, 220, 223, 0.5);
  z-index: 1;
}

.our-services-icon-arrow-container {
  background-color: #300828;
  min-width: 50px;
  height: 50px;
  border-radius: 50px;
}

.our-services-icon-arrow {
  width: 6px;
  height: 12px;
}

.our-service-item-text-container {
  margin-left: 15px;
}

p.our-service-item-text {
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #1D1E20;
  text-align: left;
}

a.our-service-item-link {
  color: #6F6D7A;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.05em;
  text-decoration: underline;
  margin-top: 10px;
}

.our-service-item-img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.our-service-item:hover {
  cursor: pointer;
  border-bottom-color: #BA4951;
}

.our-service-item:hover .our-services-icon-arrow-container {
  background-color: #BA4951;
}
