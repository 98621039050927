.default-btn {
  background-color: #51152E;
  font-size: 12px;
  font-weight: bold;
  color: #FFFFFF;
  margin: 30px 0;
  padding: 12px 20px;
}

a.default-btn {
  text-decoration: none;
}

.default-btn:hover {
  cursor: pointer;
  background-color: #BA4951;
}

@media all and (max-width: 999px) {
  .default-btn {
    font-size: 10px;
  }
}
