#section-contact {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}

.contact-divider-bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.section-contact-left {
  padding: 0 40px 30px 8%;
  position: relative;
  background-color: #1D1E20;
  z-index: 2;
}

.section-contact-left-content {
  padding: 50px 50px 100px 80px;
  z-index: 10;
}

.section-contact-left-title {
  font-weight: bold;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  margin: 50px 0 10px;
}

.section-contact-left-subtitle {
  font-family: Montserrat;
  font-size: 14px;
  line-height: 170%;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  max-width: 370px;
}

.section-contact-left-icon-divisor {
  width: 15px;
}

.contact-left-bg {
  position: absolute;
  width: 100%;
  height: 90%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.section-contact-right-content {
  padding: 50px 8% 50px 10%;
  position: relative;
  z-index: 2;
}

.section-contact-right-title {
  font-weight: bold;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  margin: 50px 0 10px;
}

.contact-send-btn {
  background-color: #51152E;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 12px;
  font-family: Montserrat;
  padding: 15px 0;
  margin-top: 15px;
}

.contact-send-btn:hover {
  background-color: #BA4951;
  cursor: pointer;
}

.contact-send-btn-sent {
  background-color: #24D06C !important;
}

.contact-right-bottom-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 200px;
  z-index: 0;
}

@media all and (max-width: 999px) {
  #section-contact {
    flex-direction: column;
    justify-content: stretch;
  }

  .section-contact-left-content {
    padding: 20px 0 50px 15px;
  }

  .fb-insta-container {
    margin-top: 80px;
  }

  .section-contact-right-title {
    text-align: left;
  }
}
