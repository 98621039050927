.page-our-services-item {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
}

.page-our-services-item-title {
  display: flex;
  flex-direction: column;
  width: 230px;
  font-size: 26px;
  line-height: 130%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  text-align: left;
}

.page-our-services-item-title p {
  margin: 0;
}

.page-our-services-item-arrow-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #DDDCDF;
}

.page-our-services-item-arrow {
  width: 8px;
}

.page-our-services-item-text-2 {
  margin: 0 10px;
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.03em;
  color: #1D1E20;
  width: 210px;
  text-align: left;
}

.page-our-services-item-text-container {
  margin: 0 0 30px 0;
}

.page-our-services-item-container {
  padding-bottom: 30px;
  border-bottom: 1px solid #51152E;
}

.page-our-services-item-title-small {
  display: none;
}

.page-our-services-item-title-high {
  display: flex;
}

@media all and (max-width: 815px) {
  .page-our-services-item-title-high {
    display: none;
  }

  .page-our-services-item-title-small {
    display: flex;
    text-align: center;
  }

  .page-our-services-item-title {
    flex-direction: row;
    margin-bottom: 30px;
    width: 300px;
    text-align: left;
  }
  .page-our-services-item-title p {
    margin-right: 10px;
  }

  .page-our-services-item {
    flex-direction: column;
    margin-top: 50px;
  }
}
