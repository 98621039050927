#section-what-we-offer {
  padding: 0 8% 30px;
  position: relative;
}

.what-we-offer-bottom-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 180px;
  z-index: 0;
}

.what-we-offer-content {
  margin: 0 50px 80px;
}

.what-we-offer-title {
  width: 80%;
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.05em;
  color: #1D1E20;
  margin-bottom: 80px;
  text-align: left;
}

@media all and (max-width: 999px) {
  .what-we-offer-bottom-bg {
    display: none;
  }

  .what-we-offer-content {
    margin: 0 0 80px 20px;
  }

  .what-we-offer-title {
    font-size: 23px;
    width: 100%;
  }

  #section-what-we-offer {
    padding-right: 4%;
  }
}
