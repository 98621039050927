@font-face {
  font-family: Playfair;
  font-style: normal;
  font-weight: 400;
  src: url(./resource/fonts/PlayfairDisplay.ttf) format('truetype');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: url(./resource/fonts/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Trajan_Pro;
  font-style: normal;
  font-weight: 400;
  src: url(./resource/fonts/Trajan_Pro.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: Playfair;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  text-align: justify;
}

p {
  padding: 0 !important;
}

.section-center {
  width: 94%;
  height: 100%;
  margin: 0 auto;
}

.divider-bg {
  max-width: 100%;
  height: 60px;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-end {
  display: flex;
  align-items: flex-end;
}

.items-stretch {
  display: flex;
  align-items: stretch;
}

.content-center {
  display: flex;
  justify-content: center;
}

.content-between {
  display: flex;
  justify-content: space-between;
}

.icon-arrow {
  width: 27px;
  height: 50px;
  z-index: 1;
  align-self: center;
}

.icon-arrow:hover {
  cursor: pointer;
}
