#section-banner {
  height: 450px;
  position: relative;
}

.section-banner-content {
  padding: 0 8%;
}

#banner-container {
  display: flex;
  flex: 1;
  position: relative;
  margin: 0 40px;
}

.banner-content {
  opacity: 0;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 60px;
  transition-property: opacity, z-index;
  transition-duration: 800ms;
}

.banner-content.active {
  opacity: 1;
  z-index: 2;
}

.banner-content-square {
  width: 110px;
  height: 34px;
  background-color: rgba(169, 167, 175, 0.2);
  font-size: 13px;
  font-weight: 700;
  color: #6F6D7A;
  margin-bottom: 20px;
  z-index: 1;
}

.transition-square-container {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 20px;
}

.transition-square {
  width: 6px;
  height: 6px;
  border: 2px solid #DDDCDF;
  background-color: #FFFFFF;
  margin: 0 5px;
}

.transition-square.active {
  border-color: #51152E;
  position: relative;
}

.transition-square.active::after {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  content: '';
  background-color: #51152E;
  animation: transition-square-fill 5s;
}

@keyframes transition-square-fill {
  from {
    width: 0;
  }
  to {
    width: 7px;
  }
}

.banner-text {
  text-align: center;
  font-size: 52px;
  letter-spacing: 0.05em;
  font-weight: normal;
  font-style: normal;
  line-height: 110%;
  z-index: 1;
}

.lgbtqi-icon {
  width: 32px;
  height: 32px;
}

.banner-subtext {
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.03em;
  color: #36373E;
  margin-top: 23px;
  font-family: Montserrat;
  z-index: 1;
}

.banner-left-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 250px;
  z-index: 0;
  max-width: 100%;
}

.banner-right-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 300px;
  z-index: 0;
}

@media all and (max-width: 999px) {
  .banner-text {
    font-size: 40px;
  }

  .banner-subtext {
    font-size: 12px;
    text-align: center;
  }

  .transition-square-container {
    bottom: 10px;
    z-index: 2;
  }
}
