.circle-icon-container {
  border-radius: 50%;
  display: flex;
  align-items: stretch;
  padding: 5px;
  border: 1px solid #6F6D7A;
  background-color: transparent;
}

.circle-icon-container-hover:hover {
  cursor: pointer;
}

.circle-icon-content {
  background-color: #6F6D7A;
}

.circle-icon-container-hover:hover .circle-icon-content {
  background-color: #BA4951;
}

.circle-icon-content {
  border-radius: 50%;
}
